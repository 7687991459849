@use '../../assets/styles/colors';
@use '../../assets/styles/structure';
@use '../../assets/styles/type';

.AddIcon {
  display: block;
  justify-self: end;
  width: type.px-to-rem(27px);
  height: type.px-to-rem(27px);
  padding: 0;
  border: 0 none;
  background-color: transparent;
  text-decoration: none;

  svg {
    width: type.px-to-rem(27px);
    height: type.px-to-rem(27px);

    path {
      fill: colors.$light-blue;
    }
  }

  &-Text {
    color: colors.$dark-grey;
    font-family: type.$fieldwork;
    font-weight: 600;
    line-height: type.px-to-rem(27px);
    white-space: nowrap;
  }

  .BigRow__withLink:hover & {
    svg path {
      fill: colors.$yellow;
    }
  }

  @include structure.for-phone-only {
    .ContentHeader & {
      svg {
        path {
          fill: colors.$white;
        }
      }

      &:hover {
        svg {
          path {
            fill: colors.$yellow;
          }
        }
      }

      &-Text {
        display: none;
      }
    }
  }

  @include structure.for-tablet-portrait-up {
    .ContentHeader__withBackLink & {
      display: none;
    }

    &:hover,
    &:active {
      svg path {
        fill: colors.$yellow;
      }
    }

    &:not(:disabled) {
      cursor: pointer;
    }

    &:disabled {
      svg path {
        fill: colors.$disabled-bg;
      }

      &:active {
        background-color: colors.$disabled-bg;
      }
    }

    .ContentHeader__withAddText.ContentHeader__withAddLink & {
      display: grid;
      grid-template-areas: 'addIcon text';
      grid-template-columns: type.px-to-rem(27px) auto;
      width: auto;
      column-gap: 0.5rem;

      .AddIcon .AddIcon-Text {
        grid-area: text;
      }

      .AddIcon svg {
        grid-area: addIcon;
      }

      .ContentHeader & {
        svg path {
          fill: colors.$white;
        }
      }
    }
  }
}
