@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';

.Login {
  align-self: center;
  justify-self: center;
  padding: type.px-to-rem(22px);

  @include structure.for-tablet-portrait-up {
    max-width: type.px-to-rem(560px);
    padding: type.px-to-rem(100px) type.px-to-rem(75px);
    background-color: colors.$light-blue;
  }
}

.Login-Logo {
  display: block;
  width: type.px-to-rem(280px);
  margin: auto;
}
