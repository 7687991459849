@use '../../assets/styles/colors';
@use '../../assets/styles/structure';
@use '../../assets/styles/type';

.CloseButton {
  display: grid;
  align-items: center;
  width: type.px-to-rem(27px);
  height: type.px-to-rem(27px);
  padding: 0;
  border: 0 none;
  background-color: transparent;
  justify-items: center;

  svg path {
    fill: colors.$white;
  }

  &:hover {
    svg path {
      fill: colors.$yellow;
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    svg path {
      fill: colors.$disabled-bg;
    }

    &:active {
      background-color: colors.$disabled-bg;
    }
  }
}
