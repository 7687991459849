@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';
@use '../../assets/styles/navigation';

$drawer-width: 400px;

.AccountsWrapper {
  .ContentHeader {
    display: grid;
    grid-template-areas:
      'one two'
      'three amount';
    grid-template-columns: auto;
    padding: type.px-to-rem(30px) type.px-to-rem(22px);
    border-top: 0 none;
    background-color: colors.$light-blue;

    .ContentHeader-H1 {
      grid-area: three;
      align-self: end;
      justify-self: start;
      color: colors.$white;
    }

    .AddButton {
      display: inherit;
      grid-area: two;
      justify-self: end;
    }

    .CloseButton {
      grid-area: two;
      align-self: start;
      justify-self: end;
    }

    .BackButton {
      grid-area: one;
    }
  }

  @include structure.for-tablet-portrait-up {
    display: grid;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    grid-template-rows: min-content auto;
    width: type.px-to-rem($drawer-width);
    overflow: hidden;
    transform: translateX(type.px-to-rem($drawer-width));
    transition: transform navigation.$navigation-transition-speed navigation.$navigation-transition-ease;
    background-color: colors.$white;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%), 0 2px 20px rgb(169 169 169 / 24%);

    .ContentHeader {
      height: type.px-to-rem(141px);

      .AddButton {
        align-self: start;
        margin-right: 2.75em;
      }

      .BackButton {
        display: none;
      }

      &.ContentHeader__withAddLink {
        .AddButton svg path {
          fill: colors.$white;
        }
      }
    }

    &.AccountsWrapper__drawerOpen {
      transform: translateX(type.px-to-rem(0));
    }

    .AccountsWrapper-ContentPane {
      padding: type.px-to-rem(16px);
      overflow: auto;
    }
  }

  @include structure.for-tablet-landscape-up {
    .ContentHeader {
      min-height: type.px-to-rem(166px);
    }
  }
}
