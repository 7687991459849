@use '../../assets/styles/type';
@use '../../assets/styles/colors';
@use '../../assets/styles/structure';

.MobileNavButton {
  position: absolute;
  z-index: 2;
  top: type.px-to-rem(38px);
  left: 1rem;
  width: 2.889em;
  height: 2.889em;
  padding: 0;
  border: 0 none;
  background: none;

  @include structure.for-tablet-portrait-up {
    display: none;
  }

  &:active {
    cursor: pointer;

    .MobileNavButton-Icon path {
      fill: colors.$yellow;
    }
  }

  // Hides the button on certain pages.
  // See setPageClass in global actions for more.
  .global-state-page-class-login &,
  .global-state-page-class-logout &,
  .global-state-page-class-accounts__new__linked &,
  .global-state-page-class-advisor_connect &,
  .global-state-page-class-accounts__new_interstitial &,
  [class$='__new'] &,
  [class*='global-state-page-class-account_categories__'] & {
    display: none;
  }

  .MobileNavButton-Icon {
    width: type.px-to-rem(27px);
    height: type.px-to-rem(19px);
  }
}
