@use '../../assets/styles/colors';
@use '../../assets/styles/structure';

.Button {
  height: 2.889em;
  padding: 0.5em 1.4em;
  border: 0 none;
  border-radius: structure.$border-radius;
  background-color: colors.$light-blue;
  color: colors.$white;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 2em;

  &:hover,
  &:active {
    background-color: colors.$yellow;
    color: colors.$blue;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    background-color: colors.$disabled-bg;
    color: colors.$disabled-text;
    cursor: not-allowed;

    &:active {
      background-color: colors.$disabled-bg;
    }
  }

  &__fullWidth {
    display: block;
    width: 100%;

    ul + &,
    button + & {
      margin-top: 1rem;
    }
  }

  &__sm {
    height: 1.889em;
    padding: 0.25em 0.75em;
    border-radius: structure.$border-small-radius;
    font-size: 0.875em;
    line-height: 1.5em;
  }

  &__danger {
    background-color: colors.$red;
    color: colors.$white;

    &:hover,
    &:active {
      background-color: colors.$red-dark;
      color: colors.$white;
    }
  }

  &__link {
    display: block;
    width: auto;
    margin-right: auto;
    margin-left: auto;
    background-color: transparent;
    color: colors.$blue;
    text-decoration: underline;

    ul + &,
    button + & {
      margin-top: 1rem;
    }
  }
}
