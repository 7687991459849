@use 'sass:math';
@use './colors';

$font-size-base: 16px;
$font-weight-bold: 700;
$font-weight-normal: 400;
$font-weight-semi-bold: 500;
$fieldwork: 'Fieldwork', sans-serif;
$sf-pro-display: 'SF Pro Display', 'Helvetica Neue', sans-serif;
$font-sizes: (
  base: $font-size-base
);
$font-weights: (
  regular: $font-weight-normal,
  medium: $font-weight-semi-bold,
  bold: $font-weight-bold
);

/// Remove the unit of a px length
/// @param {Number} $number - Number to remove px unit from
/// @return {Number} - Unitless number
@function strip-px-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return calc($number / calc($number * 0 + 1px));
  }

  @return $number;
}

/// Convert a `px` value to `rem`
/// @param {Number} $pxValue - Number to convert to `rem`
/// @return {Number} - number in rems
@function px-to-rem($pxValue) {
  @return #{math.div(strip-px-unit($pxValue), strip-px-unit($font-size-base))}rem;
}

/// Helper mixin to generate font weight helper classes
@mixin font-weights() {
  @each $key, $weight in $font-weights {
    .font-weight__#{$key} {
      font-weight: #{$weight};
    }
  }
}

/// Helper mixin to generate font size helper classes
@mixin font-sizes() {
  @each $key, $size in $font-sizes {
    .font-size__#{$key} {
      font-size: #{$size};
    }
  }
}
