@use '../../assets/styles/colors';
@use '../../assets/styles/structure';
@use '../../assets/styles/type';

.AccountForm {
  padding: type.px-to-rem(22px);
}

.AccountForm-Label {
  color: colors.$label-grey;
}

.AccountForm-Field {
  margin: 0.5em 0 0;
  border: 1px solid colors.$border-grey;
  box-shadow: structure.$form-input-shadow;

  &[readonly] {
    color: colors.$disabled-text;
    cursor: not-allowed;

    &:focus {
      outline: none;
    }
  }
}

.AccountFormWrapper {
  height: 100%;
}
