@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';
@use '../../assets/styles/navigation';

.AccountCategoryAccounts {
  background-color: colors.$grey-bg;

  .AccountCategoryList {
    display: none;
  }

  @include structure.for-tablet-portrait-up {
    background-color: transparent;

    .AccountCategoryList {
      display: inherit;
    }
  }
}

.AccountCategoryAccounts-P {
  @include structure.for-tablet-portrait-up {
    display: none;
  }
}
