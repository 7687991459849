@use '../../assets/styles/colors';

.LoginForm {
  width: 100%;
}

.LoginForm-Wrapper {
  width: 18.75rem;
  margin: 4em auto auto;
}

.LoginForm-Label {
  color: colors.$white;
}

.LoginForm-Field {
  border: 0 none;
}
