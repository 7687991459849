@use '../../assets/styles/colors';
@use '../../assets/styles/type';

.RowLink-Wrapper {
  & + .RowLink-Wrapper,
  & + .RowLinkButton-Wrapper {
    margin-top: 3em;
  }
}

.RowLink {
  display: grid;
  grid-template-columns: auto type.px-to-rem(39px);
  grid-template-rows: type.px-to-rem(80px);
  align-items: center;
  padding: 0 type.px-to-rem(22px);
  border-radius: type.px-to-rem(12px);
  background-color: colors.$white;
  box-shadow: 1px 2px 3px rgb(240 240 240 / 25%), 0 2px 20px rgb(169 169 169 / 24%);
  color: colors.$blue;
  font-family: type.$sf-pro-display;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  line-height: 1.4;
  text-decoration: none;

  & + .RowLink {
    margin-top: 1em;
  }

  svg {
    justify-self: end;

    path {
      fill: colors.$blue;
    }
  }

  &:hover {
    color: colors.$light-blue;

    svg path {
      fill: colors.$light-blue;
    }
  }

  .RowLink-Wrapper__disabled & {
    cursor: not-allowed;

    &:hover {
      color: colors.$blue;

      svg {
        justify-self: end;

        path {
          fill: colors.$blue;
        }
      }
    }
  }
}

.RowLink-P {
  margin: 0.6em 0 0;
  color: colors.$dark-grey;
  font-size: 0.9rem;
}
