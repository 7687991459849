@use './colors';
@use './type';
@use './structure';
@use './navigation';

.AccountFormWrapper {
  .ContentHeader {
    .CloseButton {
      display: none;
    }
  }

  @include structure.for-tablet-portrait-up {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: type.px-to-rem(400px);
    transform: translateX(type.px-to-rem(400px));
    transition: transform navigation.$navigation-transition-speed navigation.$navigation-transition-ease;
    background-color: colors.$white;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%), 0 2px 20px rgb(169 169 169 / 24%);

    &.ImportedAccountsWrapper__drawerOpen,
    &.AccountFormWrapper__drawerOpen {
      transform: translateX(type.px-to-rem(0));
    }

    .ContentHeader {
      display: grid;
      grid-auto-flow: row;
      grid-template: repeat(2, 1fr) repeat(2, 1fr);
      grid-template-areas:
        'one two'
        'three amount';
      min-height: type.px-to-rem(166px);
      padding: type.px-to-rem(16px) type.px-to-rem(24px);
      border-top: 0 none;
      background-color: colors.$light-blue;
      gap: 0 0;

      .ContentHeader-H1 {
        grid-area: three;
        color: colors.$white;
      }

      .CloseButton {
        display: inherit;
        grid-area: two;
        align-self: start;
      }

      .BackButton {
        grid-area: one;
      }
    }
  }
}
