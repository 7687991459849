@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';
@use '../../assets/styles/navigation';

.NewLinkedAccount {
  background-color: colors.$grey-bg;

  @include structure.for-tablet-portrait-up {
    background-color: transparent;
  }
}

.NewLinkedAccountPage-PageContent {
  margin: 0;
}

.NewLinkedAccount-P {
  @include structure.for-tablet-portrait-up {
    display: none;
  }
}
