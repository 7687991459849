@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';
@use '../../assets/styles/navigation';

.global-state-page-class-accounts__new .PrimaryLayout-Content {
  background-color: colors.$grey-bg;

  @include structure.for-tablet-portrait-up {
    background-color: colors.$white;
  }
}

.NewAccountInterstitial-Wrapper {
  @include structure.for-tablet-portrait-up {
    background-color: transparent;

    .AccountCategoryList {
      display: inherit;
    }

    .ContentHeader-H1 {
      color: colors.$white;
    }
  }
}

.NewAccountInterstitial-P {
  @include structure.for-tablet-portrait-up {
    display: none;
  }
}
