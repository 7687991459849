@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';

.Logout {
  align-self: center;
  justify-self: center;
  padding: type.px-to-rem(22px);

  @include structure.for-tablet-portrait-up {
    max-width: type.px-to-rem(560px);
    padding: type.px-to-rem(100px) type.px-to-rem(75px);
    background-color: colors.$light-blue;
  }

  &-Logo {
    display: block;
    width: type.px-to-rem(280px);
    margin: auto;
  }

  &-P {
    margin-top: 2em;
    color: colors.$white;
    font-size: type.px-to-rem(18px);
    text-align: center;
  }

  &-ErrorsWrapper {
    display: grid;
    grid-auto-flow: row;
    text-align: center;
  }
}
