@use '../../assets/styles/colors';

.Home-test {
  display: grid;
  padding: 2em;
  border: 2px solid colors.$dark-blue;
  text-align: center;

  .Home-testResponse {
    margin-top: 1em;
    padding: 1em;
    border: 2px dotted colors.$dark-blue;
    color: colors.$dark-grey;

    h5 {
      margin: 0 0 0.4em;
    }

    p {
      margin: 0;
    }
  }
}
