@use './type';
@use './colors';

////////////////////////////////////////
// general
$border-radius: 12px;
$border-small-radius: 7px;
$box-shadow-small: 0 0 10px rgb(0 0 0 / 20%);

////////////////////////////////////////
// form related
$form-input-shadow: 0 0 5px rgb(0 0 0 / 8%), 0 1px 2px rgb(169 169 169 / 10%);

////////////////////////////////////////
// responsive tools
@mixin for-phone-only {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
