@use '../../assets/styles/colors';
@use '../../assets/styles/type';

.NavigationLink {
  display: grid;
  grid-template-columns: type.px-to-rem(39px) auto;
  grid-template-rows: type.px-to-rem(39px);
  align-items: center;
  color: colors.$white;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
  text-decoration: none;
  text-transform: capitalize;

  & + .NavigationLink {
    margin-top: 1em;
  }

  .NavigationLink-Icon {
    display: grid;
    align-items: center;
    width: type.px-to-rem(39px);
    height: type.px-to-rem(39px);
  }

  svg {
    max-width: type.px-to-rem(26px);
    color: colors.$white;
  }

  &__active,
  &:hover {
    color: colors.$yellow;

    svg {
      color: colors.$yellow;
    }
  }
}
