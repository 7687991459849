@use '../../assets/styles/colors';
@use '../../assets/styles/structure';
@use '../../assets/styles/type';

.GoButton {
  display: block;
  width: type.px-to-rem(11px);
  height: type.px-to-rem(19px);
  padding: 0;
  border: 0 none;
  background-color: transparent;
  color: colors.$dark-grey;

  &:hover,
  &:hover svg {
    color: colors.$yellow;
  }

  &:active {
    box-shadow: 1px 1px 1px rgb(0 0 0 / 40%);
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    color: colors.$disabled-bg;

    &:active {
      background-color: colors.$disabled-bg;
    }
  }

  .BigRow__withLink:hover & {
    color: colors.$yellow;
  }
}
