@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';

.ContentHeader {
  display: grid;
  position: relative;
  grid-template-areas:
    'navButton addLink'
    'text text';
  grid-template-columns: 1fr;
  align-items: center;
  height: type.px-to-rem(160px);
  padding: type.px-to-rem(30px) type.px-to-rem(22px);
  background-color: colors.$light-blue;

  .ContentHeader-H1 {
    grid-area: text;
    align-self: end;
    color: colors.$white;
  }

  &.ContentHeader__withAddLink.ContentHeader__withAddText,
  .PrimaryLayout-Content > &.ContentHeader__withBackLink,
  .PrimaryLayout-Content > &.ContentHeader__withAddLink.ContentHeader__withAddText {
    .AddButton {
      grid-area: addLink;
      align-self: start;
      margin-top: 1em;
    }

    .ContentHeader-H1 {
      grid-area: text;
    }
  }

  &__AccountsWrapper {
    display: none;
  }

  &-P__ContentHeaderBalance {
    display: none;
    grid-area: amount;
    align-self: end;
    margin: 0;
    color: colors.$white;
    font-size: type.px-to-rem(26px);
    font-weight: 600;
    line-height: type.px-to-rem(30px);
    justify-self: end;
  }

  &.ContentHeader__AccountsWrapper {
    .ContentHeader-P__ContentHeaderBalance {
      display: block;
    }
  }

  @include structure.for-phone-only {
    &__AccountsWrapper,
    &__AccountCategoryAccounts {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      .CloseButton {
        display: none;
      }
    }
  }

  @include structure.for-tablet-portrait-up {
    .ContentHeader-H1 {
      color: colors.$blue;
    }

    .PrimaryLayout-Content > &.ContentHeader__withBackLink,
    .PrimaryLayout-Content > &.ContentHeader__withAddLink.ContentHeader__withAddText {
      grid-template-areas: 'text addLink';
      grid-template-rows: 1fr;
      align-items: center;

      .AddButton {
        align-self: center;
        margin-top: 0;
      }

      .ContentHeader-H1 {
        align-self: center;
      }
    }

    .PrimaryLayout-Content > & {
      height: min-content;
      padding: type.px-to-rem(32px) type.px-to-rem(32px) type.px-to-rem(16px);
      border-top: type.px-to-rem(47px) solid colors.$light-blue;
      background-color: colors.$white;
    }
  }

  @include structure.for-tablet-landscape-up {
    padding: type.px-to-rem(44px) type.px-to-rem(75px) type.px-to-rem(38px);

    .PrimaryLayout-Content > & {
      padding: type.px-to-rem(44px) type.px-to-rem(75px) type.px-to-rem(38px);
    }
  }
}
