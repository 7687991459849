@use '../../assets/styles/navigation';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';
@use '../../assets/styles/colors';

.PrimaryLayout {
  position: relative;
  transform: translateX(0);
  transition: transform navigation.$navigation-transition-speed navigation.$navigation-transition-ease;

  &.global-state-nav-open {
    transform: translateX(navigation.$navigation-width);
  }

  @include structure.for-tablet-portrait-up {
    display: grid;
    grid-template-areas: 'navigation content';
    grid-template-columns: type.px-to-rem(256px) auto;
  }

  &.global-state-page-class-login,
  &.global-state-page-class-logout {
    grid-template-areas: 'login-form';
    grid-template-columns: auto;
    height: 100%;
  }

  &.global-state-page-class-accounts__new_interstitial,
  &.global-state-page-class-accounts__new__linked,
  &.global-state-page-class-account_categories,
  &.global-state-page-class-advisor_connect,
  &.global-state-page-class-root {
    height: 100%;
  }
}

.PrimaryLayout-Content {
  display: grid;
  grid-template-rows: min-content auto;
  align-items: baseline;
  height: 100vh;
  overflow-y: hidden;
  background-color: colors.$white;

  .global-state-page-class-login &,
  .global-state-page-class-logout & {
    grid-template-areas: 'login';
    grid-template-rows: auto;
    align-items: center;
    background-color: colors.$blue;
    justify-items: center;
  }

  .global-state-page-class-account_categories &,
  .global-state-page-class-accounts__new_interstitial &,
  .global-state-page-class-advisor_connect &,
  .global-state-page-class-accounts__new__linked & {
    height: 100%;
    overflow-y: auto;
  }

  .global-state-page-class-account_categories & {
    > .ContentHeader {
      display: inherit;
    }
  }

  @include structure.for-phone-only {
    .NewLinkedAccountPage-PageContent > .AddAccountRowLinks,
    > .ContentHeader {
      display: none;
    }

    .global-state-page-class-root &,
    .global-state-page-class-not-found & {
      grid-template-rows: min-content auto;

      > .ContentHeader {
        display: grid;
        grid-template-rows: 1fr;
        justify-content: end;
      }
    }

    .global-state-page-class-accounts__new_interstitial & > .ContentHeader,
    .global-state-page-class-advisor_connect & > .ContentHeader {
      display: inherit;
    }
  }

  @include structure.for-tablet-portrait-up {
    order: 1;
    height: 100%;

    & .NewAccountInterstitial-Wrapper {
      height: auto;
    }
  }
}
