@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';

.PageContent {
  height: 100%;
  padding: type.px-to-rem(22px);
  overflow-y: auto;
  color: colors.$dark-grey;

  &.AccountCategoryAccounts,
  &.AccountCategoryNewAccount,
  &.EditAccount,
  &.NewAccount {
    height: 100vh;
  }

  @include structure.for-phone-only {
    &.AccountCategoryAccounts,
    &.AccountCategoryNewAccount,
    &.EditAccount,
    &.NewAccount,
    &.NewLinkedAccountPage-PageContent {
      height: 100vh;
      padding: 0;
    }
  }

  @include structure.for-tablet-portrait-up {
    height: 100%;
    padding: type.px-to-rem(16px) type.px-to-rem(32px);
    overflow-y: inherit;

    .NewAccountInterstitial-PageContent {
      padding-top: type.px-to-rem(32px);
    }
  }

  @include structure.for-tablet-landscape-up {
    padding: type.px-to-rem(16px) type.px-to-rem(75px) type.px-to-rem(32px);

    .PrimaryLayout-Content > & {
      padding: type.px-to-rem(16px) type.px-to-rem(75px) type.px-to-rem(32px);
    }
  }
}
