@use '../../assets/styles/type';
@use '../../assets/styles/colors';
@use '../../assets/styles/structure';

.BigList {
  display: grid;
  margin: 0;
  padding: 0;
  padding: type.px-to-rem(10px);
  border-radius: type.px-to-rem(12px);
  background-color: colors.$white;
  box-shadow: 1px 2px 3px rgb(240 240 240 / 25%), 0 2px 20px rgb(169 169 169 / 24%);
  list-style: none;

  &__empty {
    align-items: center;
    height: 100%;
    justify-items: center;
    padding-top: 3em;
    padding-bottom: 3em;

    .AccountsWrapper__drawerOpen & {
      height: fit-content;
    }
  }

  .AccountsWrapper-ContentPane & {
    margin: type.px-to-rem(22px);

    @include structure.for-tablet-portrait-up {
      margin: 0;
    }
  }
}
