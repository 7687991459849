.FieldLabel {
  display: grid;
  grid-template-rows: min-content;
  height: fit-content;

  & + .FieldLabel {
    margin-top: 2.25em;
  }

  & + .Form-Button {
    margin-top: 2.5em;

    + .Form-Button {
      margin-top: 1em;
    }
  }

  .Form-Error + & {
    margin-top: 0.65em;
  }

  & + .Form-Error + .Form-Button {
    margin-top: 1.1em;
  }

  &.LoginForm-Label + .Form-Button {
    margin-top: 4em;
  }

  &.LoginForm-Label + .Form-Error + .Form-Button {
    margin-top: 2.55em;
  }
}
