@use '../../assets/styles/colors';
@use '../../assets/styles/type';

.Paragraph {
  font-size: 1rem;
}

.Paragraph-strong {
  font-weight: type.$font-weight-bold;
}
