@use '../../assets/styles/colors';
@use '../../assets/styles/structure';
@use '../../assets/styles/type';

.Modal {
  display: grid;
  position: fixed;
  z-index: 3;
  align-items: center;
  width: 90%;
  max-width: type.px-to-rem(280px);
  height: fit-content;
  padding: 2em;
  border-radius: structure.$border-small-radius;
  background-color: colors.$white;
  box-shadow: structure.$box-shadow-small;

  &-Footer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 2em;
  }

  &-Backdrop {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 60%);
  }

  &-Wrapper {
    display: grid;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-items: center;
  }

  &-Close {
    display: grid;
    position: absolute;
    z-index: 3;
    top: 1em;
    right: 2.75em;
    align-items: center;
    width: type.px-to-rem(34px);
    height: type.px-to-rem(34px);
    border: 0 none;
    border-radius: structure.$border-small-radius;
    background-color: colors.$white;
    box-shadow: structure.$box-shadow-small;
    color: colors.$dark-grey;
    cursor: pointer;
    justify-items: center;

    &:hover {
      color: colors.$light-blue;
    }
  }
}
