@use 'sass:math';
@use 'sass:color';

@use '../../assets/styles/colors';
@use '../../assets/styles/navigation';
@use '../../assets/styles/structure';

.NavMask {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: translateX(100vw);
  transition: opacity navigation.$navigation-transition-speed navigation.$navigation-transition-ease transform
    navigation.$navigation-transition-speed navigation.$navigation-transition-ease;
  border: 0 none;
  opacity: 0;
  background: rgba($color: #000, $alpha: 10%);
  text-indent: -999em;

  .global-state-nav-open & {
    transform: translateX(0);
    opacity: 1;
  }

  @include structure.for-tablet-portrait-up {
    display: none;
  }
}
