@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';

svg.DonutGraph {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  pointer-events: all;

  .DonutGraph-Label {
    tspan {
      font-family: type.$fieldwork !important;
      font-weight: 400;
    }

    &__Center {
      tspan {
        font-size: type.px-to-rem(28px) !important;
      }
    }
  }
}

.DonutGraph-Wrapper {
  margin: type.px-to-rem(22px);
  padding: type.px-to-rem(18px);
  border-radius: type.px-to-rem(12px);
  background-color: colors.$white;
  box-shadow: 1px 2px 3px rgb(240 240 240 / 25%), 0 2px 20px rgb(169 169 169 / 24%);

  @include structure.for-tablet-portrait-up {
    margin-right: 0;
    margin-left: 0;
  }
}
