@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';
@use '../../assets/styles/navigation';

.Drawer {
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100vw;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  width: 85vw;
  height: 100%;
  padding: 1.875rem;
  background: colors.$blue;

  .global-state-page-class-login &,
  .global-state-page-class-logout & {
    display: none;
  }

  &-Top {
    align-self: start;
  }

  .global-state-nav-open & {
    transform: translateX(15vw);
    transform: translateX(15vw);
  }

  &-Logo {
    display: block;
    align-self: end;
    max-width: 13em;
  }

  @include structure.for-tablet-portrait-up {
    position: static;
    order: 0;
    width: 16rem;
  }
}
