@use '../../assets/styles/colors';
@use '../../assets/styles/type';
@use '../../assets/styles/structure';

svg.AccountCategoryDonutGraph {
  path {
    fill: colors.$blue !important;

    & + path {
      fill: colors.$light-graph-blue !important;
    }
  }

  .DonutGraph-Label {
    & + .DonutGraph-Label {
      display: none;
    }
  }
}
