@use '../../assets/styles/colors';
@use '../../assets/styles/structure';

.Loading {
  display: grid;
  align-items: center;
  width: 100%;
  height: 100vh;
  justify-items: center;
  pointer-events: none;

  svg {
    stroke: colors.$blue;
  }
}
