@use '../../assets/styles/colors';
@use '../../assets/styles/structure';
@use '../../assets/styles/type';

.BackButton {
  display: block;
  width: type.px-to-rem(11px);
  height: type.px-to-rem(19px);
  padding: 0;
  transform: rotate(-180deg);
  border: 0 none;
  background-color: transparent;

  svg path {
    fill: colors.$white;
  }

  &:hover {
    svg path {
      fill: colors.$yellow;
    }
  }

  &:active {
    box-shadow: 1px 1px 1px rgb(0 0 0 / 40%);
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    svg path {
      fill: colors.$disabled-bg;
    }

    &:active {
      background-color: colors.$disabled-bg;
    }
  }

  @include structure.for-tablet-portrait-up {
    .ContentHeader__withBackLink & {
      align-self: normal;
      margin: type.px-to-rem(4px) 0;
    }
  }
}
