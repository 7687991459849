@use '../../assets/styles/type';
@use '../../assets/styles/structure';
@use '../../assets/styles/colors';

.BigRow {
  display: grid;
  grid-row-gap: 0.75em;
  grid-template:
    'title action'
    'description action';
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  padding: 0;

  & + .BigRow {
    border-top: 1px solid colors.$light-grey;
  }

  &-Link {
    display: grid;
    grid-row-gap: 0.75em;
    grid-template:
      'title action'
      'description action';
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    padding: type.px-to-rem(18px) type.px-to-rem(22px);
    color: colors.$blue;
    text-decoration: none;

    &:hover {
      background-color: colors.$grey-bg;
      color: colors.$light-blue;
    }
  }

  &:first-child {
    .BigRow-Link {
      border-top-left-radius: type.px-to-rem(9px);
      border-top-right-radius: type.px-to-rem(9px);
    }
  }

  &:last-child {
    .BigRow-Link {
      border-bottom-right-radius: type.px-to-rem(9px);
      border-bottom-left-radius: type.px-to-rem(9px);
    }
  }

  &__noLink {
    padding: type.px-to-rem(18px) type.px-to-rem(22px);
  }

  &__withLink {
    grid-template: 'link';
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  @include structure.for-tablet-portrait-up {
    &__noAccounts {
      grid-template-columns: auto type.px-to-rem(40px);
      grid-template-rows: type.px-to-rem(44px);
    }
  }

  &-Title {
    grid-area: title;
    justify-self: start;
    align-self: auto;
    color: colors.$blue;
    font-size: type.px-to-rem(20px);
    line-height: 1.1em;
  }

  &-Description {
    grid-area: description;
    justify-self: start;
    align-self: end;
    margin: 0;
    color: colors.$dark-grey;
    font-size: type.px-to-rem(14px);
    line-height: 1em;
  }

  &-LinkedMeta {
    display: grid;
    justify-content: end;
    color: colors.$disabled-bg;
  }

  .BigRow__action {
    display: flex;
    grid-area: action;
    flex-direction: column;
    align-self: baseline;
    justify-content: space-between;
    height: 100%;
    justify-self: end;

    .Paragraph {
      margin: 0;
    }

    a,
    span {
      display: grid;
      align-items: center;
      height: 100%;
      color: colors.$dark-grey;
      justify-items: end;

      &:hover {
        color: colors.$light-blue;
      }
    }

    svg {
      width: auto;
      height: type.px-to-rem(20px);
    }

    &.AccountCategoryRow-Action {
      align-self: center;
    }
  }
}
