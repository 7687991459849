@use './colors';
@use './partials';
@use './structure';
@use './type';

@import-normalize;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100vh;

  @include structure.for-tablet-portrait-up {
    height: 100%;
  }
}

body {
  margin: 0;
  background-color: colors.$blue;
  font: type.$font-weight-normal #{type.$font-size-base} / 1.3em type.$sf-pro-display;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow-x: hidden;
}

@include type.font-sizes;
@include type.font-weights;
