@use 'sass:color';

$blue: #00567d;
$light-blue: #0095a9;
$dark-blue: color.adjust($blue, $lightness: -20%);
$dark-grey: #414042;
$disabled-bg: #cccfd6;
$disabled-text: #8e93a2;
$light-graph-blue: #1ecad3;
$light-grey: #e2e2e8;
$no-accounts-grey: #cdcdcd;
$white: #fff;
$yellow: #e0e621;
$grey-bg: #f5f5f5;
$label-grey: #606776;
$border-grey: #e9ebf0;
$red: #e0245e;
$red-dark: #c81835;
