@use '../../assets/styles/colors';
@use '../../assets/styles/structure';
@use '../../assets/styles/type';

.Form {
  display: grid;
  grid-auto-flow: row;
}

.Form-Button {
  &.Form-Button__toggleContrast {
    @include structure.for-tablet-portrait-up {
      background-color: colors.$blue;

      &:not(:disabled) {
        background-color: colors.$blue;
        color: colors.$white;
      }

      &:disabled {
        background-color: colors.$disabled-bg;
        color: colors.$disabled-text;
        cursor: not-allowed;

        &:active {
          background-color: colors.$disabled-bg;
        }
      }
    }
  }
}

.Form-Field {
  height: 3.25em;
  padding: 0.875em 1em;
  border-radius: structure.$border-radius;
}

.Form-Error {
  width: calc(100% - 3em);
  width: max-content;
  margin: 0.6em auto 0;
  padding: 0.1em 0.5em;
  border-radius: 4px;
  background-color: colors.$yellow;
  color: colors.$dark-grey;
  font-size: 0.85em;
  font-weight: type.$font-weight-bold;
  line-height: 1.45em;
}
